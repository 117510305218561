<template>
    <div class="publication-wrapper">
        <div class="publication-list-box">
            <div class="ds-flex center-between" style="padding-bottom: 20px">
                <p class="count-box ds-flex center-start">총 <span class="blue-highlight">{{ corrList.length }}개</span>의 Publications가 있습니다.</p>
                <div class="search-box ds-flex center-between">
                    <input type="text" placeholder="검색어를 입력해주세요" v-model="searchText" @keyup.enter="searchList()">
                    <img src="@/assets/news/search.png" @click="searchList()" />
                </div>
            </div>
            <div class="publication-list ds-flex start-start">
                <div v-for="list in currPageList" :key="list" class="publication-box" @click="openDetailPage(list)">
                    <div class="publication-img-box">
                        <img :src="'/'+upload.dir+'/'+upload.menu+'/'+list['upload_name']">
                    </div>
                    <div class="publication-title-box ds-flex start-">
                        <p class="title">{{list.title}}</p>
                    </div>
                </div>
            </div>
            <div class="bottom-box ds-flex center-center">
                <pagenation ref="pagenation" @page="changePage"></pagenation>
                <!-- <button @click="openWritePage()">글 작성</button> -->
            </div>
        </div>
    </div>
</template>

<script>
import Pagenation from "../Pagenation.vue";
import {API} from "../../api.js";
// import moment from "moment";

export default {
  name: 'Publications',
  components: {
    Pagenation
  },
  data: () => ({
    upload: {
        server: '',
        dir: 'upload',
        menu: 'publication',
    },
    pubList: [], // 전체 뉴스 리스트
    corrList: [], // 선택한 연도에 해당되는 리스트
    currPageList: [], // 현재 페이지에서 보여주고 있는 리스트
    currPage: 1,
    currTab: 'All',
    searchText: ''
  }),
  methods: { 
    // 검색 결과에 맞는 리스트를 설정한다.
    searchList() {
        let list = this.pubList;
        let txt = (this.searchText).toUpperCase();

        this.currPage = 1;
        this.corrList = [];

         for(let i=0; i<list.length; i++) {
            let title = (list[i].title).toUpperCase();
            if(title.includes(txt)) {
                this.corrList.push(list[i]);
            }
            if(i===list.length-1) {
                this.$refs.pagenation.setSize(this.corrList.length, 12);
                this.setCurrList();
            }
        }
    },
    // 전체 리스트를 가져온다.
    getPubList() {
        let singleton = this;
        let param = {
        table: this.upload.menu,
        idx: '',
        option: ''
      }
         API.getList(param, function(status, res){
            // console.log('res >> ', res.data);
            if(res.data.length > 0) {
                for(let i=0; i<res.data.length; i++) {
                    singleton.pubList.push(res.data[i]);
                    // singleton.pubList[i].date = moment(singleton.pubList[i].date).format('YYYY-MM-DD')

                    if(i === res.data.length-1) {
                        // console.log('singleton.pubList >> ', singleton.pubList);
                        singleton.$refs.pagenation.setSize(singleton.pubList.length, 12);
                        singleton.corrList = singleton.pubList;
                        singleton.setCurrList();
                    }
                }
            }
        })
    },
    openWritePage() {
        this.$router.push({
            name:'NewsWriteView',
        });
    },
    openDetailPage(news) {
        if(news.exlink===1 && news.url !== '') {
            window.open(news.url);
        } else if(news.exlink===0) {
            if(news.contents.length > 11) { // 내용이 없는 뉴스는 기본 길이가 11임
                this.$router.push({
                    name:'NewsDetailView',
                    query: {idx: news.idx}
                });
            }
        }
    },
    // 현재 페이지에 보여질 리스트 set
    setCurrList() {
      this.currPageList = [];
      var range = this.currPage * 12;

      for (let i = range - 12; i < range; i++) {
        if (this.corrList[i] !== undefined) {
          this.currPageList.push(this.corrList[i]);
        }
      }
    },
    // 페이지 이동 버튼 클릭 시 동작
    changePage(page) {
      window.scrollTo(0,0);
      this.currPage = page;
      this.setCurrList();
    },
  },
  mounted() {
    this.getPubList();
  },
  created() {
  }
}
</script>

<style scoped>
.publication-wrapper {
    color: #191E2C;
    border-top: 1.5px solid #00ACEB;
    padding: 80px 0;
}

.publication-title{
    padding-left: 120px;
}

.publication-title h1 {
  font-size: 45px;
  font-weight: bold;
  padding-right: 24px;
  padding-left: 50px;
}

.publication-title p {
  font-size: 20px;
  font-weight: bold;
}

.publication-wrapper .publication-title h1 {
    width: 100%;
    text-align: left;
    padding-left: 0;
}

.publication-title p {
    color: #505050;
}

.top-box {
    position: relative;
    padding-top: 250px;
    border-bottom: 1.5px solid #DDDDDD;
    overflow: hidden;
    height: 445px;
    box-sizing: border-box;
}

.top-box-bg {
    position: absolute;
    bottom: -130px;
    right: 120px;
}

.tab-box {
    margin: 70px 0 0 70px;
    box-sizing: border-box;
}

.year {
    font-weight: bold;
    color: #A3A3A3;
    font-size: 20px;
    width: 170px;
    border-right: 2px solid #A3A3A3;
}

.year.last {
    border-right: none;
}

.tab {
    width: 170px;
    cursor: pointer;
}

.tab > .blue-box {
    width: 74px;
    height: 4px;
    background-color: #191E2C;
    margin-top: 20px;
    transition: .5s;
    opacity: 0;
}

.tab > .blue-box.active {
    opacity: 1;
}

.publication-wrapper .publication-list-box {
    width: 1240px;
    margin: auto;
    box-sizing: border-box;
}

.news-count {
    padding: 45px 0;
    text-align: left;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.publication-list-box .publication-box {
  width: 295px;
  height: 555px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 20px 20px 0;
}

.publication-list-box .publication-box:nth-child(4n) {
  margin-right: 0;
}

.publication-list-box .publication-img-box {
  width: 100%;
  background-color: #DDDDDD;
  height: 420px;
}

.publication-list-box .publication-img-box > img {
    width: 100%;
    height: 100%;
    border: 1px solid #DBDEE7;
}

.publication-list-box .publication-title-box {
    width: 100%;
    height: 120px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 30px;
    align-content: space-between;
    text-align: center;
} 

.publication-list-box .publication-title-box .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #1E1E1E;
    width: 100%;
    text-align: center;
    word-break: keep-all;
}

.publication-list-box .publication-title-box .date {
    font-size: 14px;
    line-height: 14px;
    color: #979CA8;
}

.publication-list {
    max-width: 1240px;
}

.search-box {
    width: 400px;
    height: 40px;
    border: 1px solid #DDDDDD;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px 15px;
}

.search-box > input {
    border: none;
    height: 100%;
    width: 87%
}

.search-box > img {
    width: 17.5px;
    height: 17.5px;
    cursor: pointer;
}

.bottom-box {
    /* padding: 55px 0 80px 0; */
    max-width: 1365px;
    padding-top: 40px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.publication-list {
    min-height: calc(100vh - 1049px);
}

@media screen and (max-width: 1240px) {
    .publication-wrapper {
        padding: 40px 0;
    }
    
    .publication-wrapper .publication-list-box {
        width: 768px;
    }

    .publication-list-box .publication-box {
        height: 72px;
    }

    .search-box {
        width: 246px;
        height: 30px;
        font-size: 11px;
        padding: 0 15px ;
    }

    .search-box > img {
        width: 12px;
        height: 12px;
    }

    .count-box {
        font-size: 12px;
    }

    .publication-list-box .publication-box {
        width: 180px;
        height: 346px;
    }

    .publication-list-box .publication-img-box {
        height: 254px;
    }

    .publication-list-box .publication-title-box .title {
        font-size: 10px;
    }

    .publication-list-box .publication-title-box {
        height: 92px;
        padding: 10px 0;
    }

    .publication-list-box .publication-box {
        margin: 0 16px 0 0;
    }

    .publication-list {
        min-height: calc(100vh - 751.5px);
    }
}

@media screen and (max-width: 768px) {
    .publication-wrapper {
        border-top: none;
        max-width: 688px;
        margin: auto;
    }

    .publication-wrapper .publication-list-box {
        width: 100%;
    }

    .publication-list-box .publication-box {
        width: 160px;
        height: 344px;
    }

    .publication-list-box .publication-img-box {
        height: 226px;
    }

    .publication-list-box .publication-title-box .title {
        font-size: 11px;
    }

    .bottom-box {
        padding: 40px 0;
    }

    .publication-list {
        min-height: calc(100vh - 670px);
    }
}

@media screen and (max-width: 480px) {
    .count-box {
        font-size: 13px;
    }

    .search-box {
        width: 100%;
    }

    .search-box > input {
        font-size: 12px;
    }

    .publication-wrapper {
        padding: 0;
        max-width: 360px;
        margin: auto;
    }

    .publication-list-box .publication-box {
        margin-right: 0;
    }

    .publication-list {
        min-width: 360px;
        justify-content: space-between;
    }

    .publication-list-box .publication-box {
        width: 175px;
    }

    .publication-list-box .publication-title-box .title {
        font-size: 12px;
    }
}
</style>
