import axios from "axios"

export var API = {
    SERVER_BASEURL: '',

    saveEditor: function(param, callback){ // 작성한 뉴스를 저장한다.
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/news/write',
            headers:{
            },
            params:{
                'title': param.title,
                'contents': param.contents,
                'thumbnail': param.thumbnail,
                'originName': param.originName,
                'exlink': param.exlink,
                'url': param.url,
                'writer': param.writer
            },
            // data: formData,
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getNewsList: function(page, callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/news/list',
            headers:{
            },
            params:{
                "page" : page,
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getNewsYear: function(callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/news/year',
            headers:{
            },
            params:{
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getNewsContents: function(idx, callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/news/detail',
            headers:{
            },
            params:{
                "idx" : idx,
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getIrList: function(callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/ir/list',
            headers:{
            },
            params:{
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getIrYear: function(callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/ir/year',
            headers:{
            },
            params:{
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getIrContents: function(idx, callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/ir/detail',
            headers:{
            },
            params:{
                "idx" : idx,
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getList: function(param, callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/get/list',
            headers:{
            },
            params:{
                'table': param.table,
                'idx': param.idx,
                'option': param.option
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    deleteList: function(table, idx, callback){
        const requestOpts = {
            method : "POST",
            url : this.SERVER_BASEURL + '/delete/list',
            headers:{
            },
            params:{
                'table': table,
                'idx': idx
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    insertPopupList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/insert/popup',
            headers:{
            },
            params:{
                'title': param.title,
                'originName': param.originName,
                'uploadName': param.uploadName,
                'posit_x': param.posit_x,
                'posit_y': param.posit_y,
                'size': param.size,
                'writer': param.writer,
                'exlink': param.exlink,
                'url': param.url,
                'seq': param.seq,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    updatePopupList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/update/popup',
            headers:{
            },
            params:{
                'idx': param.idx,
                'title': param.title,
                'originName': param.originName,
                'uploadName': param.uploadName,
                'posit_x': param.posit_x,
                'posit_y': param.posit_y,
                'size': param.size,
                'writer': param.writer,
                'exlink': param.exlink,
                'url': param.url,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    insertCertiList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/insert/certificate',
            headers:{
            },
            params:{
                'category': param.category,
                'title': param.title,
                'contents': param.contents,
                'originName': param.originName,
                'uploadName': param.uploadName,
                'writer': param.writer,
                'seq': param.order,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    updateCertiList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/update/certificate',
            headers:{
            },
            params:{
                'idx': param.idx,
                'category': param.category,
                'title': param.title,
                'contents': param.contents,
                'originName': param.originName,
                'uploadName': param.uploadName,
                'writer': param.writer,
                'seq': param.order,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    insertPubliList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/insert/publication',
            headers:{
            },
            params:{
                'title': param.title,
                'url': param.url,
                'originName': param.originName,
                'uploadName': param.uploadName,
                'writer': param.writer,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    updatePubliList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/update/publication',
            headers:{
            },
            params:{
                'idx': param.idx,
                'title': param.title,
                'url': param.url,
                'originName': param.originName,
                'uploadName': param.uploadName,
                'writer': param.writer,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getYear: function(callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/get/year',
            headers:{
            },
            params:{
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    insertHisList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/insert/history',
            headers:{
            }, 
            params:{
                'category': param.category,
                'contents': param.contents,
                'bold': param.bold,
                'seq': param.order,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    updateHisList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/update/history',
            headers:{
            }, 
            params:{
                'idx': param.idx,
                'category': param.category,
                'contents': param.contents,
                'bold': param.bold,
                'seq': param.order,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    insertNewsList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/insert/news',
            headers:{
            },
            params:{
                'title': param.title,
                'contents': param.contents,
                'thumbnail': param.thumbnail,
                'originName': param.originName,
                'exlink': param.exlink,
                'url': param.url,
                'writer': param.writer,
                'selectedDate': param.selectedDate
            },
            // data: formData,
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    updateNewsList: function(param, callback){
        const requestOpts = {
            method: "POST",
            url : this.SERVER_BASEURL + '/update/news',
            headers:{
            },
            params:{
                'idx': param.idx,
                'title': param.title,
                'contents': param.contents,
                'thumbnail': param.thumbnail,
                'originName': param.originName,
                'exlink': param.exlink,
                'url': param.url,
                'writer': param.writer,
                'selectedDate': param.selectedDate
            },
            // data: formData,
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    insertIrList: function(param, callback){
        const formData = new FormData();
        formData.append("contents", param.contents);

        axios.post(this.SERVER_BASEURL + '/insert/ir', formData, {
            headers: {
            },
            params: {
                'title': param.title,
                'writer': param.writer,
                'selectedDate': param.selectedDate
            }
        }).then(result => callback(false, result))
          .catch(err => callback(err));
    },

    updateIrList: function(param, callback){
        const formData = new FormData();
        formData.append("contents", param.contents);

        axios.post(this.SERVER_BASEURL + '/update/ir', formData, {
            headers: {
            },
            params: {
                'idx': param.idx,
                'title': param.title,
                'writer': param.writer,
                'selectedDate': param.selectedDate
            }
        }).then(result => callback(false, result))
          .catch(err => callback(err));
    },

    // 로그인
    // loginReq: function(param, callback){
    //     const requestOpts = {
    //         method: "POST",
    //         url: this.SERVER_BASEURL + '/login',
    //         headers:{
    //             'Access-Control-Allow-Origin': '*', 
    //             'Access-Control-Allow-Credentials': true,
    //             'Content-type' : 'application/json'
    //         },
    //         params:{
    //             "id": param.id,
    //             "passwd": param.pw
    //         },
    //         json:true
    //     }
    //     axios(requestOpts)
    //         .then(result => callback(false, result))
    //         .catch(err => callback(err))
    // },

    login: function(param, callback){
        const requestOpts = {
            method: "POST",
            url: this.SERVER_BASEURL + '/login/req',
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Access-Control-Allow-Credentials': true,
                'Content-type' : 'application/json'
            },
            params:{
                "id": param.id,
                "passwd": param.pw
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },
    
    // 유저 정보 get
    getUserInfo: function(token, callback){
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/userinfo',
            headers:{
            },
            params:{
                'token': token,
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    // 회원가입 > id 중복확인
    checkDuplication: function(id, callback){
        const requestOpts = {
            method: "GET",
            url: this.SERVER_BASEURL + '/duplication',
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Access-Control-Allow-Credentials': true,
                'Content-type' : 'application/json'
            },
            params:{
                "id": id,
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    // 회원가입 요청
    signUp: function(param, callback){
        const requestOpts = {
            method: "POST",
            url: this.SERVER_BASEURL + '/signup',
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Access-Control-Allow-Credentials': true,
                'Content-type' : 'application/json'
            },
            params: {
                "id": param.id,
                "passwd": param.pw,
                "level": 0,
                "group": param.selected,
                "name": param.name,
                "number": param.number,
                "email": param.email
            },
            json:true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },

    getSeq: function(callback){ // popup 등록 시 순서를 가져옴
        const requestOpts = {
            method : "GET",
            url : this.SERVER_BASEURL + '/get/seq',
            headers:{
            },
            params:{
            },
            json: true
        }
        axios(requestOpts)
            .then(result => callback(false, result))
            .catch(err => callback(err))
    },
}