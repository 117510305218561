<template>
  <div class="partner-wrapper">
    <div class="cooperation-box">
      <h1 class="title">파트너</h1>
      <div class="list ds-flex center-start">
        <img v-for="idx in 12" :key="idx" :src="require(`@/assets/main/partnerships/${idx}.svg`)" />
      </div>
    </div>
    <div class="cooperation-box">
      <h1 class="title">구축 사례</h1>
      <div class="list ds-flex center-start">
        <img v-for="idx in 21" :key="idx" :src="require(`@/assets/main/case/${idx}.svg`)" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Partner',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>
.partner-wrapper {
  border-top: 1.5px solid #00ACEB;
}
.cooperation-box {
  width: 1240px;
  margin: auto;
  padding: 80px 0;
}
.home .cooperation-box {
  padding: 120px 0;
}
.cooperation-box:last-child {
  padding-top: 0;
}
.cooperation-box .list {
  width: 100%;
}
.cooperation-box .list img {
  margin-right: 68px;
}
.home .title {
  width: 100%;
  font-size: 36px;
  font-weight: 800;
  color: #1E1E1E;
  padding-bottom: 25px;
  text-align: left;
}
.title {
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .cooperation-box .list img:nth-child(6n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1240px) {
  .cooperation-box {
    width: 768px;
  }
  .cooperation-box .list img {
    width: 70px;
    height: 70px;
  }
  .title {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .cooperation-box {
    padding: 60px 40px;
    width: 690px;
  }
  .cooperation-box .list img {
    margin-right: 18px;
  }
}
@media screen and (max-width: 480px) {
  .cooperation-box .list {
    display: flex;
    flex-wrap: wrap;
    width: 345px;
  }
  .cooperation-box .list img {
    margin-right: 16px;
  }
  .cooperation-box {
    padding: 50px 0;
    width: 345px;
    margin: auto;
  }
}
</style>
